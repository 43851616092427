<template>
    <div class="height__full">
        <div class="app-modal_In">
            <div class="app-modal__header d-flex f-between p-5 m-title-modal">
                <p class="large--title m-0">{{ $t('message.products') }}</p>
                <div>
                    <crm-store-update-close
                    :permission="$options.name"
                    :button_type="'close'"
                    @c-close="close()"
                    ></crm-store-update-close>
                </div>
            </div>
        </div>
    <div class="gc-card__body px-3 py-4">
        <div class="app-form__group mb-0">
            <div
                class="
                    gc-card
                    rounded-sm
                    mb-4
                    p-relative
                    mr-3
                    px-3 py-3
                "
            >
                <div v-loading="loadingData">
                    <template>
                        <el-table 
                            header-row-class-name="header__class"
                            row-class-name="row__class"
                            :data="dealProductsList" 
                            show-summary 
                            :summary-method="getSummaries" 
                            border stripe 
                            style="width: 100%">
                            <el-table-column type="index" width="50"> </el-table-column>
                            <el-table-column :label="$t('message.deal')" width="90">
                                <template slot-scope="scope"> {{ scope.row.deal_id }} </template>
                            </el-table-column>
                            <el-table-column :label="$t('message.name')">
                                <template slot-scope="scope"> 
                                    <span v-if="scope.row.type === 'product'">{{ scope.row.product ? scope.row.product.name : '' }} </span>
                                    <span v-if="scope.row.type === 'package'">{{ $t('message.package') }} </span>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('message.barcode')">
                                <template slot-scope="scope"> 
                                    <span>{{ scope.row.barcode }} </span>    
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('message.quantity_y')">
                                <template slot-scope="scope"> 
                                    <span v-if="scope.row.type === 'product'">{{ scope.row.self_incoming_quantity }}  </span>
                                    <span v-if="scope.row.type === 'package'"> 1 </span>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('message.current_quantity')">
                                <template slot-scope="scope">
                                    <div v-show="!scope.row.totalQuantity && scope.row.type === 'product'">
                                        <el-input
                                            :min="0"
                                            type="number"
                                            size="mini"
                                            :disabled="true"
                                            v-model="scope.row.self_remainder"
                                        ></el-input>
                                    </div>
                                    <span v-show="scope.row.totalQuantity && scope.row.type === 'package'">
                                        {{scope.row.totalQuantity}}
                                    </span>
                                </template>
                            </el-table-column>

                            <el-table-column :label="$t('message.to_filial')">
                                <template slot-scope="scope"> {{ scope.row.to_filial }} </template>
                            </el-table-column>
                            
                            <el-table-column :label="columns.weight.title">
                                <template slot-scope="scope" > 
                                    <span v-show="scope.row.type === 'product'"> {{ showWeight(scope.row) }} </span>
                                    <span v-show="scope.row.type === 'package'"> {{scope.row.total_weight}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('message.comment')">
                                <template slot-scope="scope"> 
                                    <span v-show="scope.row.hasOwnProperty('comment')"> {{ scope.row.comment }} </span>
                                    <span  v-show="!scope.row.hasOwnProperty('comment')">{{scope.row.containerType ? scope.row.containerType.name : ''}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('message.show')" width="90">
                                <template slot-scope="scope" > 
                                    <el-button v-if="scope.row.type == 'package'"
                                        @click="showPackageProducts(scope.row)" 
                                        type="primary" icon="el-icon-view" circle>
                                    </el-button>
                                    <el-button v-if="scope.row.type == 'product' && scope.row.parcel"
                                        @click="updateParcelProducts(scope.row)" 
                                        type="primary" icon="el-icon-view" circle>
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>         
                </div>
            </div>
        </div>
    </div>

    <!-- Upakovka productlari -->
    <el-dialog class="dialog__modal" :title="$t('message.products')" :visible.sync="dialogProductsList" width="60%" @closed="emptyCortainerProducts()" :append-to-body="true" >
        <div v-loading="loadingProducts">
            <el-table :data="containerProducts">
                <el-table-column type="index" width="50"> </el-table-column>
                <el-table-column prop="deal_id" :label="$t('message.deal')" width="90"> </el-table-column>
                <el-table-column prop="name" :label="$t('message.name')">
                    <template slot-scope="scope">
                        {{ scope.row.product ? scope.row.product.name : '' }}
                    </template>
                </el-table-column>

                <el-table-column prop="comment" :label="$t('message.comment')">
                    <template slot-scope="scope">
                        {{ scope.row.comment ? scope.row.comment : '' }}
                    </template>
                </el-table-column>

                <el-table-column prop="barcode" :label="$t('message.barcode')"></el-table-column>
                <el-table-column prop="remainder" :label="$t('message.quantity')"></el-table-column>

                <el-table-column prop="weight" :label="$t('message.weight_in_kg')"></el-table-column>
                <el-table-column prop="weight_in_pounds" :label="$t('message.weight_in_pounds')"></el-table-column>
            </el-table>
        </div>
    </el-dialog>

    <!-- product posilki -->
    <el-dialog 
        class="dialog__modal" 
        :title="$t('message.products')" 
        :append-to-body="true"
        :visible.sync="dialogParcelProductsList" 
        width="60%" 
        @opened="$refs['parcelProducts'] ? $refs['parcelProducts'].afterOpen() : ''">
        <parcel-products-list ref="parcelProducts" :selectedItem="selectedProd"></parcel-products-list>
    </el-dialog>
           
</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import { formatMoney, formatNumber } from "@/filters/index";
import parcelProductsList from "@/views/waiting_product/components/parcel-products-list";

export default {
    mixins: [form, drawer],
    components: {
        parcelProductsList
    },

    props: {
        container:{
            default: null
        },
    },

    data: () => ({
        mode: true,
        checked: true,
        dealProductsList: [],
        loadingData: false,
        loadingProducts: false,
        dialogProductsList: false,
        dialogParcelProductsList: false,
        selectedProd: {}
    }),
    computed: {
        ...mapGetters({
            model: "containers/model",
            columns: "dealProducts/columns",
            containerProducts: "packageForProductCirculations/products",
        }),
        total_weight: function () {
            let total = 0;
            this.dealProductsList.forEach((product) => {
                if(product.type === 'product'){
                    if(product.batches && product.batches.length > 1){
                        product.batches.forEach(batch => {
                            total += parseFloat(batch.weight);
                        });
                    }else{
                        total += parseFloat(product.weight);
                    }
                }
                if(product.type === 'package'){
                    total += parseFloat(product.total_weight);
                }
                
            });
            return parseFloat(total);
        },
    },
    methods: {
        ...mapActions({
            show: "containers/show",
            emptyCortainerProducts: "packageForProductCirculations/empty",
            showProducts: "packageForProductCirculations/showProducts",
        }),
        updateParcelProducts(parcel) {
            this.selectedProd = parcel;
            this.dialogParcelProductsList = true;
        },
        afterOpen(){
            this.fetchData();
        },  
        afterLeave(){
            this.dealProductsList = [];
        },  
        fetchData(){
            this.loadingData = true;  
            this.show({id: this.container.id, type: 'show'}).then(res => {
                this.loadingData = false;
                let prods = JSON.parse(JSON.stringify(this.model.products.filter(el => el.remainder > 0)));
                let packs = JSON.parse(JSON.stringify(this.model.packages));
                this.dealProductsList = prods.concat(packs);
            }).catch(err => {
                this.loadingData = false;
            });
        },
        showPackageProducts(pack){
            this.dialogProductsList = true;
            this.loadingProducts = true;
            this.showProducts(pack.id).then(res => {
                    this.loadingProducts = false;
                }).catch(err => {
                    this.loadingProducts = false;
                });
        },
        showAndUpdateBatches(row){
            this.batchDialog = true;
            this.selectedProduct = JSON.parse(JSON.stringify(row));
        },
        showWeight(row){
            if(row.batches && row.batches.length > 1){
                let weights = 0;
                row.batches.forEach(element => {
                    weights += parseFloat(element.weight)
                });
                return _.round(weights, 6) ;
            }else{
                return row.weight;
            }
        },
        getSummaries(param) {
            const { columns } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 1) {
                    sums[index] = this.$t('message.total');
                    return;
                }
                if (column.label === this.columns.weight.title) {
                    sums[index] = formatNumber(this.total_weight, 1) + ' ' + this.$t('message.kg');
                    return;
                }
               
                if (column.label === (this.columns.weight.title + ' %')) {
                    sums[index] = formatMoney(this.weight, 2);
                    return;
                }
            });

            return sums;
        },
        
    }
    
}
</script>

<style>

</style>
