<template>
      <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'" v-loading="loadingData">
            <div class="deal-task-tracker" >
            <div class="card-table-body ifon-add-title-block">
                <el-row>
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="18">
                        <div class="content-in__title filter-title">
                            <h1 class="content-title mr-3" :class="mode ? 'content__titleday' : 'content__titlenight'">{{$t('message.reciever_warehouse')}}</h1>
                            <div class="block-sarche">
                                <div class="header__search">
                                    <div class="filter_filial">
                                            <select-from-filial
                                                :size="'medium'"
                                                :select_only="false"
                                                :class="mode ? 'filter__day' : 'filter__night'"
                                                :placeholder="columns.from_filial.title"
                                                v-model="filterForm.from_filial_id"
                                            >
                                            </select-from-filial>
                                            <select-to-filial
                                                :size="'medium'"
                                                :select_only="false"
                                                :class="mode ? 'filter__day' : 'filter__night'"
                                                :placeholder="columns.to_filial.title"
                                                v-model="filterForm.to_filial_id"
                                            >
                                            </select-to-filial>
                                    </div>
                                    <!-- <crm-input
                                        :size="'large'"
                                        :className="'w100'"
                                        class="mr-3"
                                        :class="mode ? 'input__day' : 'input__night'"
                                        v-model="filterForm.search"
                                        :icon="'el-icon-search'"
                                    ></crm-input> -->
                                </div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="6">
                        <div class="content__setting">
                            <div class="custom__button ml-3" >
                                <el-button @click="showIncomingHistory()" size="medium" :type="showHistory ? 'primary' : 'info'">
                                    {{ showHistory ? $t('message.active') : $t('message.history') }}
                                </el-button>
                            </div>
                            
                            <div class="custom__button ml-3" >
                                <el-button @click="clientProductsDrawer = true" size="medium"  type="primary" v-if="permissions.some(per => per.slug == 'clients.sendClientProductsFromFilial')">
                                    {{ $t('message.client_products') }}
                                </el-button>
                            </div>
                            
                            <div class="custom__button ml-3" @click="newCargoDrawer = true" v-if="permissions.some(per => per.slug == 'containers.setContainersInFilial')">
                                <el-button type="primary" icon="el-icon-s-order" size="medium" >
                                    {{ $t('message.add') }}
                                </el-button>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="card-table-header table-crm-smart custom__scroll">
                <div class="card-table-header table-crm-smart custom__scroll">
                    <el-tabs v-model="activeTabName" @tab-click="handleTabClick" type="card">
                        <el-tab-pane name="packages">
                            <span slot="label" :class="activeTabName !== 'packages' ? (mode ? '' : 'text-white') : ''"> {{ $t('message.packages') }} </span>
                            <packages-table ref="packagesTable" :parentFilter="filterForm" v-if="activatedTabs.includes('packages')"></packages-table>
                        </el-tab-pane>
                        <el-tab-pane name="products">
                            <span slot="label" :class="activeTabName !== 'products' ? (mode ? '' : 'text-white') : ''"> {{ $t('message.products') }} </span>
                            <products-table ref="productsTable" :parentFilter="filterForm" v-if="activatedTabs.includes('products')"></products-table>
                        </el-tab-pane>
                        <el-tab-pane name="parcels">
                            <span slot="label" :class="activeTabName !== 'parcels' ? (mode ? '' : 'text-white') : ''"> {{ $t('message.parcels') }} </span>
                            <parcels-table ref="parcelsTable" :parentFilter="filterForm" v-if="activatedTabs.includes('parcels')"></parcels-table>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
            
            <el-drawer class="bg-se" :with-header="false"
                :visible.sync="clientProductsDrawer"
                ref="clientProductsDrawer"
                @opened="drawerOpened('clientProductsDrawerChild')"
                @closed="drawerClosed('clientProductsDrawerChild')" 
                size="90%">
                <client-products 
                    ref="clientProductsDrawerChild"
                    drawer="clientProductsDrawer"
                />
            </el-drawer>

            <el-drawer class="bg-se" :with-header="false"
                :visible.sync="newCargoDrawer"
                ref="newCargoDrawer"
                @opened="drawerOpened('newCargoDrawerChild')"
                @closed="drawerClosed('newCargoDrawerChild')" 
                size="90%">
                <pallets 
                    ref="newCargoDrawerChild"
                    drawer="newCargoDrawer"
                />
            </el-drawer>
        </div>
    </div>

</template>

<script>
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
import form from "@/utils/mixins/form";
import drawer from "@/utils/mixins/drawer";
import pallets from "./components/crm-pallet";
import ClientProducts from "./components/client-products";
import selectFromFilial from "@/components/selects/select-from-filial";
import selectToFilial from "@/components/selects/select-to-filial";
import productsTable from "./components/products-table";
import packagesTable from "./components/packages-table";
import parcelsTable from "./components/parcels-table";

export default {
    name: "tasks",
    mixins: [list, form, drawer],
    components: {
        pallets,
        ClientProducts,
        selectFromFilial,
        selectToFilial,
        productsTable,
        packagesTable,
        parcelsTable
    },
    
    data() {
        return {
            created: false,
            newCargoDrawer: false,
            clientProductsDrawer: false,
            showHistory: false,
            activeTabName: 'packages',
            activatedTabs: ['packages'],
        };
    },
    computed: {
        ...mapGetters({
            list: "productCirculations/list",
            permissions: "auth/permissions",
            columns: "productCirculations/columns",
            pagination: "productCirculations/pagination",            
            filter: "productCirculations/filter",
            sort: "productCirculations/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "productCirculations/index",
            updatePackageList: "packageForProductCirculations/index",
            setPagination: "productCirculations/setPagination",
            updateSort: "productCirculations/updateSort",
            updateFilter: "productCirculations/updateFilter",
            updateColumn: "productCirculations/updateColumn",
            updatePagination: "productCirculations/updatePagination",
            show: "productCirculations/show",
            empty: "productCirculations/empty",
            delete: "productCirculations/destroy",
            refreshData: "productCirculations/refreshData",
        }),
        handleTabClick(tab, event){
            if(this.activatedTabs.includes(tab.name)){
                this.fetchData();
            }
            if(!this.activatedTabs.includes(tab.name)){
                this.activatedTabs.push(tab.name);
            }
        },
        changeFilials() {
            if(this.filterForm.from_filial_id && this.filterForm.to_filial_id){
                let from = JSON.parse(JSON.stringify(this.filterForm.from_filial_id));
                let to = JSON.parse(JSON.stringify(this.filterForm.to_filial_id));
                this.$set(this.filterForm, 'from_filial_id', to);
                this.$set(this.filterForm, 'to_filial_id', from);
            }
        },
        async fetchData() {
            if(this.activeTabName === 'packages' && this.$refs.packagesTable && _.isFunction(this.$refs.packagesTable.debouncedFetchData)){
                this.$refs.packagesTable.debouncedFetchData();
            }
            if(this.activeTabName === 'products' && this.$refs.productsTable && _.isFunction(this.$refs.productsTable.debouncedFetchData)){
                this.$refs.productsTable.debouncedFetchData();
            }
            if(this.activeTabName === 'parcels' && this.$refs.parcelsTable && _.isFunction(this.$refs.parcelsTable.debouncedFetchData)){
                this.$refs.parcelsTable.debouncedFetchData();
            }
        },
        
        showIncomingHistory(){
            this.showHistory = !this.showHistory;
            this.$set(this.filterForm, 'showHistory', this.showHistory)
        },
    
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('productCirculations/EMPTY_LIST');
        next()
    },
};
</script>

<style>
.coloa3 {
   background: #ff3f3f;
}
.container__tasks {
    width: 100% ;
    margin: 0 !important;
}
.test__width {
    width: 100% !important;
}
.filter_filial{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 500px;
    height: 20px;
}
.mm__row__green {
    background-color: rgb(83, 180, 83);
}

.mm__row__yellow {
    background-color: rgb(236, 179, 73);
}
</style>
